<template>
  <div class="alarm_data">
    <dec-block-title>报警信息</dec-block-title>
    <vue-seamless-scroll :data="alarmData" class="seamless-warp">
      <ul class="item">
        <li v-for="(item, index) in alarmData" :key="index">
          <p>报警类型：{{ item.type }}</p>
          <p>报警点：{{ item.LocaleName }}</p>
          <p>报警时间：{{ dateFormat(item.AcquitAt) }}</p>
          <p>报警地址：{{ item.LocaleAddress }}</p>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import decBlockTitle from "../decorations/blockTitle2";
import vueSeamlessScroll from "vue-seamless-scroll";
import {dateFormater} from "@/util";

export default {
  name: "alarmData",
  components: {
    decBlockTitle,
    vueSeamlessScroll,
  },
  props: {
    countyLevelData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      alarmData: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let exceedStandardYesterdays = []
      let filterAbnormallyUsedYesterdays = []
      if (this.countyLevelData.exceedStandardYesterdays) {
        exceedStandardYesterdays = (this.countyLevelData.exceedStandardYesterdays || []).map((v) => {
          v.type = '超标报警'
          return v
        })
      }
      if (this.countyLevelData.filterAbnormallyUsedYesterdays){
        filterAbnormallyUsedYesterdays = (this.countyLevelData.filterAbnormallyUsedYesterdays || []).map((v) => {
          v.type = '不正常使用净化器'
          return v
        })
      }
      this.alarmData = [
        ...exceedStandardYesterdays,
        ...filterAbnormallyUsedYesterdays
      ]
    },
    dateFormat(date) {
      return dateFormater(date);
    },
  },
  watch: {
    countyLevelData: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.alarm_data {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .seamless-warp {
    height: 100%;
    width: 100%;
    margin: auto;
    overflow: hidden;

    .item {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 8px;

      li {
        color: #fff;
        // border-bottom: 1px solid #fff;
        overflow: hidden;
        font-size: 8px;
        line-height: 14px;

        &:nth-child(odd) {
          background: rgba(31, 77, 135, 0.2);
        }
      }
    }
  }
}
</style>
